<template>
  <div class="container-fluid mb-4">
    <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <CRow>
      <CCol sm="12" class="my-4">
        
      </CCol>
      <CCol sm="12" class="mt-2 mb-5">
          <step-progress :steps="steps" :current-step="currentStep"></step-progress>
      </CCol>
    </CRow>
    <CRow v-if="pasoUno">
      <CCol sm="6" v-for="item in items" :key="item.Nro" class="border-right py-1">
        <CRow>
          <CCol sm="2" class="text-center">
            <label @click="modalA(2, item)">
              <CIcon :name="item.Icono2" :class="item.Estilo2" class="mr-1" />
            </label>
            <label @click="modalA(1, item)">
              <CIcon :name="item.Icono" :class="item.Estilo" class="mr-1" />
            </label>
          </CCol>
          <CCol sm="9">
            {{item.TpEdiName}}
            {{item.TpEdiTransacName}}
            {{item.EdiFileName}}
          </CCol>
          <CCol sm="1">
            <CInputCheckbox
              :id="'c_'+item.Nro" 
              :ref="'c_'+item.Nro"
              @update:checked="testCheck($event, item)"
              size="lg"
              class="float-right ml-3"
              custom
              :disabled="item.NoEditable"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" class="text-right mt-2">
        <CButton
          color="success"
          @click="pasoSiguiente(1)"
          :disabled="soloActivos"
        >
          <CIcon name="cil-arrow-right" class="mr-1" />
          {{$t('label.next')}}
        </CButton>
      </CCol>
    </CRow>
    <CRow v-if="!pasoUno">
      <CCol sm="10" class="mb-2">
        <CInput
          v-model.trim="texto.EditFile"
          :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
          label="EDI"
          disabled
          addLabelClasses="text-right"
        />
      </CCol>
      <CCol sm="5" class="mb-3">
        <CInput
          v-model.trim="texto.equipmentQuantity"
          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
          :label="$t('label.equipmentQuantity')"
          disabled
          addLabelClasses="text-right"
        />
      </CCol>
      <CCol sm="5" class="mb-3">
        <CInput
          v-model.trim="texto.resource"
          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
          :label="$t('label.resources')"
          disabled
          addLabelClasses="text-right"
        />
      </CCol>
      <CCol sm="5" class="mb-3">
        <CInput
          v-model.trim="texto.teus"
          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
          label="TEUS"
          disabled
          addLabelClasses="text-right"
        />
      </CCol>
      <CCol sm="5" class="mb-3">
        <CInput
          v-model.trim="texto.workHour"
          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
          :label="$t('label.workHour')"
          disabled
          addLabelClasses="text-right"
        />
      </CCol>
      <CCol sm="5" class="mb-3">
        <CInput
          v-model.trim="texto.operationType"
          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
          :label="$t('label.operationType')"
          disabled
          addLabelClasses="text-right"
        />
      </CCol>
      <CCol sm="5" class="mb-3">
        <CInput
          v-model.trim="texto.estimatedTime"
          :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
          :label="$t('label.estimatedTime')"
          disabled
          addLabelClasses="text-right"
        />
      </CCol>
      <CCol sm="12" class="text-right mt-2">
        <CButton
          color="wipe"
          class="mr-1"
          @click="pasoSiguiente(0)"
        >
          <CIcon name="returnSolid" class="mr-1" />
          {{$t('label.return')}}
        </CButton>
        <CButton
          color="add"
          class="mr-1"
          :disabled="blockSuccess"
          @click="guardar"
        >
          <CIcon name="checkAlt" class="mr-1" />&nbsp; 
          {{ $t('button.confirm') }}
        </CButton>
      </CCol>
    </CRow>
    <alerta-modal :modal="modalAlerta" @cerrarModal="modalAlerta=false" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import GeneralMixin from "@/_mixins/general";
import AlertaModal from '@/pages/planificacion-estiba/archivos/detalles/alerta-modal';
import StepProgress from 'vue-step-progress';

//data
function data() {
  return {
    loadingOverlay: false,
    modalAlerta: false,
    blockSuccess:false,
    StowagePlanningId: '',
    current: '',
    currentStep: 0,
    steps: [this.$t('label.selectEdi'), this.$t('label.confirmation')],
    items: [],
    EdiFileId: '',
    pasoUno: true,
    texto: {
      EditFile: '',
      equipmentQuantity: '',
      resource: '',
      teus: '',
      workHour: '',
      operationType: '',
      estimatedTime: '',
    }
  };
}

function getData () {
  this.loadingOverlay = true;
  this.items = [];
  this.StowagePlanningId = this.planificacionId;
  this.$http.get("StowagePlanningEdiFileConfirmation-list", { StowagePlanningId: this.StowagePlanningId }) //this.EdiFileId
  .then(response => {
    let listado = [...response.data.data];
    this.items = listado.map((item) => Object.assign({}, item, {
      ...item,
      Icono: item.FgAlert==1 ? 'cil-warning' : '',
      Estilo: item.FgAlert==1 ? 'text-center text-warning' : '',
      Icono2: item.FgError==1 ? 'cil-exclamation' : '',
      Estilo2: item.FgError==1 ? 'text-center text-danger' : '',
      NoEditable: (item.FgError==1) ? true : false,
    }));
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function getPreview (Edi) {
  this.loadingOverlay = true;
  this.$http.get("DeviceTransferPreview", { EdiFileId: Edi })
  .then(response => {
    let listado = [...response.data.data];
    if (listado.length > 0){
      this.texto.workHour = listado[0].WorkHours;
      this.texto.EditFile = listado[0].EdiFileName;
      this.texto.estimatedTime = listado[0].EstimatedDays;
      this.texto.resource = listado[0].CantResource;
      this.texto.teus = listado[0].Teus;
      this.texto.equipmentQuantity = listado[0].CantCargo;
      this.texto.operationType = listado[0].TpEdiTransacName;
    }    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    this.loadingOverlay = false;
  });
}

function volver() {
  this.$emit('child-refresh', true);
}

function modalA(valor,item) {
  if (item.NoEditable) {
    let arr = Object.assign({ label: valor, value: item.EdiFileId });
    this.modalAlerta=arr;
  }  
}

function testCheck(event, item) {
  this.EdiFileId = '';
  for (let i = 0; i < this.items.length; i++) {
    let field = document.querySelector("input[id=c_"+this.items[i].Nro+"]");
    field.checked = false;
  }
  
  let field = document.querySelector("input[id=c_"+item.Nro+"]");
  field.checked = event;
  if (event==true) {
    this.EdiFileId = item;
  }
}

function pasoSiguiente(paso) {
  this.currentStep = paso;
  if (paso == 0) {
    this.pasoUno = true;
    this.EdiFileId = '';
    this.blockSuccess = false;
    this.getData();
  } else {
    this.pasoUno = false;
    this.getPreview(this.EdiFileId.EdiFileId)
  }  
}

function limpiarDatos() {
  this.EdiFileId = '';
  this.pasoUno = true;
  this.currentStep = 0;
}

function guardar () {
  this.loadingOverlay = true;
  let DeviceJson = {
    EdiFileId: this.EdiFileId.EdiFileId,
    //UserId: this.user.UserId,
  }

  let ruta = "DeviceTransfer-insert";
  if ((this.EdiFileId.TpEdiTransacId=="0BDB045B-F7CF-4782-8ACF-AC04A1B342CF") || (this.EdiFileId.TpEdiId=="74423312-1450-4B16-B837-D5620CDCDD1D")) {
    ruta = "DeviceLoadingTransfer-insert";
  }
  
  this.$http.ejecutar("POST", ruta, DeviceJson, { root: 'DeviceJson' })
  .then(response => {
    let res = [response.data.data];
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: res[0].Response,
      type: "success"
    });
    this.blockSuccess = true;
    this.loadingOverlay= false;

    this.pasoSiguiente(0);
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.loadingOverlay= false;
  });
}

//COMPUTED
function soloActivos() {
  return this.EdiFileId=='' ? true : false;
}

export default {
  name: "transferir-dispositivo",
  data,
  props: {},
  mixins: [GeneralMixin],
  components:{
    AlertaModal,
    StepProgress
  },
  methods: {
    getData,
    volver,
    modalA,
    testCheck,
    pasoSiguiente,
    limpiarDatos,
    getPreview,
    guardar
  },
  mounted() {
    if(!["", null, undefined].includes(this.planificacionId) && this.tabIndex === 7){
      this.getData();
      this.limpiarDatos();
    }
  },
  computed: {
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      user: state => state.auth.user,
      tabIndex: (state) => state.planificacionestiba.tabIndex,
    }),
    soloActivos
  },
  watch:{
    tabIndex(newValue){
      if(newValue === 7){
        this.getData();
        this.limpiarDatos();
      }
    }
  }
};
</script>

<style>
    .step-progress__wrapper{
        width: 80%;
        margin: 10px auto 40px auto;
        position: relative;
    }
    
    .step-progress__wrapper-before{
        background-color:grey;
        transform:translateY(-50%) perspective(1000px)
    }
    
    .step-progress__wrapper-after,.step-progress__wrapper-before{
        content:"";
        position:absolute;
        left:0;
        top:50%;
        height:12px;
        width:100%
    }

    .step-progress__step{
        z-index:2;
        position:relative;
        --activeColor:#5281b2 !important;
        --passiveColor:grey;
        --activeBorder:3px;
        --passiveBorder:3px
    }
    
    .step-progress__wrapper-after{
        background-color:#5281b2 !important;
        transform:scaleX(0) translateY(-50%) perspective(1000px);
        transform-origin:left center;
        transition:transform .5s ease
    }
    
    .step-progress__bar{
        width:100%;
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    
    .step-progress__step span{
        color:var(--passiveColor);
        transition:.3s ease;
        display:block;
        font-size:15px;
        transform:translateZ(0) scale(1) perspective(1000px);
        text-align:center;opacity:1
    }
    
    .step-progress__step--active .step-progress__step-label,.step-progress__step--active span{
        color:var(--activeColor)
    }
    
    .step-progress__step--active .step-progress__step-icon{
        opacity:1
    }
    
    .step-progress__step--valid .step-progress__step-icon{
        opacity:1;
        transform:translate3d(-50%,-50%,0) scale(1) perspective(1000px)
    }
    
    .step-progress__step--valid span{
        color:var(--activeColor);
        opacity:0;
        transform:translateZ(0) scale(2) perspective(1000px)
    }
    
    .step-progress__step--valid .step-progress__step-label{
        color:var(--activeColor)
    }
    
    .step-progress__step:after{
        content:"";
        position:absolute;
        z-index:-1;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%) perspective(1000px);
        width:75px;
        height:75px;
        background-color:#fff;
        border-radius:50%;
        border:var(--passiveBorder) solid var(--passiveColor);
        transition:.3s ease
    }
    
    .step-progress__step:after{
        width:40px;
        height:40px
    }
    
    .step-progress__step--active:after,.step-progress__step--valid:after{
        border:var(--activeBorder) solid var(--activeColor)
    }
    
    .step-progress__step--valid:after{
        background-color:var(--activeColor)
    }
    
    .step-progress__step-label{
        position:absolute;
        top:calc(100% + 15px);
        left:50%;
        transform:translateX(-50%) perspective(1000px);
        white-space:nowrap;
        font-size:14px;
        font-weight:600;
        color:grey;
        transition:.3s ease
    }
    
    .step-progress__step-icon{
        font-size:36px;
        color:#fff;
        position:absolute;
        left:50%;
        top:50%;
        transition:transform .3s ease;
        opacity:0;
        transform:translate3d(-50%,-50%,0) scale(0) perspective(1000px)
    }
    
    .step-progress__step-icon{
        font-size:17px
    }

    .text-warning {
      cursor: pointer;
    }

    .text-danger {
      cursor: pointer;
    }
</style>